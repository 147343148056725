import { AddUserVehicle, getProperUrl } from "Contexts/VehicleContext/CoreVehicleRequests";
import { createFitmentAddedSegmentEventFromCustomerProject } from 'Clutch/Utilities/Instrumentation/Fitment/fitmentSegmentEvents'

export async function AddNewRaceTypeToGarage(
    raceTypeId,
    userGuid,
    raceTypes,
    url,
    updateUserGuid,
    updateRaceTypes,
    setIsLoading,
    selectedFacetData,
    sendCustomSegmentTrackEvent
  ) {

    try {
      setIsLoading(true);
  
      var response = await AddUserVehicle(
        userGuid,
        url,
        null,
        null,
        null,
        raceTypeId,
        null,
        selectedFacetData,
      );

      // WIFR-32920 This needs to be refactored in order to support customerProjects when we get around to doing that
      const newCustomerProjects = response?.addNewProjectToGarage?.projects
      if (response?.addNewProjectToGarage?.success && !response?.addNewProjectToGarage?.alreadyExisted) {
        const projectId = response?.addNewProjectToGarage?.addedCustomerProjectId
        const customerProject = newCustomerProjects?.find(x => x.projectId === projectId)
        const segmentEvent = createFitmentAddedSegmentEventFromCustomerProject(customerProject)
        sendCustomSegmentTrackEvent(segmentEvent)
      }

      if (!response) {
        setIsLoading(false);
      }
  
      const user = response?.addNewProjectToGarage?.webUser;
      await updateRaceTypes(
        raceTypes,
        raceTypeId,
        newCustomerProjects
      );
      if (user?.userGuid) {
        updateUserGuid(user?.userGuid);
      }
  
      let racecar = raceTypes
        ? raceTypes.find((x) => x.raceTypeId === raceTypeId)
        : null;
  
      if (racecar) {
        window.location.href = getProperUrl(racecar);
      } else {
        throw "Unable to find racecar in vehicle state";
      }
    } catch (err) {
      console.error("Unable to add race vehicle to garage", err);
      setIsLoading(false);
    }
  }
  